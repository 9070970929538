const LANG_LIST: string = process.env.NEXT_PUBLIC_LANG_LIST || '';
export const LANGUAGES: string[] = LANG_LIST.split(',');
export type Country = 'ua';
export const DEFAULT_LANG = process.env.NEXT_PUBLIC_DEFAULT_LANG || 'uk';
export const CURRENCY = process.env.NEXT_PUBLIC_CURRENCY || 'uah';
export const NEW_YEAR_AVAILABLE = process.env.NEXT_PUBLIC_NEW_YEAR_AVAILABLE;
export const COUNTRY = process.env.NEXT_PUBLIC_COUNTRY as Country;
export const LOGO_URL = `https://img4.zakaz.ua/store_logos/zakaz_${COUNTRY}.svg`;
export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL as string;
export const GOOGLE_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_API_KEY;
export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
export const ENVIRONMENT_NAME = process.env.NEXT_PUBLIC_ENVIRONMENT_NAME;
export const SKIN = process.env.NEXT_PUBLIC_SKIN;
export const GOOGLE_SITE_VERIFICATION = process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION;
export const EXTRA_CHAINS_URL = process.env.NEXT_PUBLIC_EXTRA_CHAINS_URL as string;
export const SITE_TITLE = process.env.SITE_TITLE;

export const IS_SERVER = typeof window === 'undefined';

export const apiRoot = process.env.NEXT_PUBLIC_API_URI;

export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
export const CROSS_DOMAIN_GTM_ID = process.env.NEXT_PUBLIC_CROSS_DOMAIN_GTM_ID;

export const MIN_SEARCH_LENGTH = 3;
export const LATEST_API_VERSION = '63';
export const DEFAULT_PRODUCTS_PER_PAGE = 28;
export const DEFAULT_PRODUCTS_FOR_SLIDER_COUNT = 28;

export const ZENDESK_KEY = process.env.NEXT_PUBLIC_ZENDESK_KEY;
export const WITH_UBERCATALOG = process.env.NEXT_PUBLIC_WITH_UBERCATALOG;

export const RECAPTCHA_PUBLIC_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_KEY;
export const COUNTRY_CODE = process.env.NEXT_PUBLIC_COUNTRY_CODE || '+380';

export const MAP_MIN_ZOOM = 2;
export const MAP_MAX_ZOOM = 18;

export const HEADER_HEIGHT = 64;
export const HEADER_HEIGHT_PHONE = 57;
export const MARGIN_UNDER_HEADER = 12;
export const FILTERS_PANEL_HEIGHT = 48;

export const IS_ANDROID =
  typeof window !== 'undefined' &&
  window.navigator &&
  window.navigator.userAgent &&
  window.navigator.userAgent.toLowerCase().indexOf('android') > -1;
export const IS_IOS =
  typeof window !== 'undefined' &&
  window.navigator &&
  !!window.navigator.userAgent &&
  /iPad|iPhone|iPod/.test(window.navigator.userAgent);

export const IOS_APP_ID = process.env.NEXT_PUBLIC_IOS_APP_ID;
export const ANDROID_APP_ID = process.env.NEXT_PUBLIC_ANDROID_APP_ID;
export const MOBILE_PLATFORM = IS_ANDROID
  ? 'android'
  : IS_IOS
    ? 'ios'
    : null;

// COUNTRIES
export const IS_UKRAINE = COUNTRY === "ua";
export const metaXChain = '*';

export const HORECA_B2B_LINK = process.env.NEXT_PUBLIC_HORECA_B2B_LINK;
export const FILTER_PRICE_SEPARATOR = '-';

export const ZAKAZ_CAREERS_URL = "https://careers.zakaz.ua/?utm_source=zakaz.ua&utm_medium=link&utm_campaign=redirect";
export const FIRST_LEVEL_FILTER_QUERY_SEPARATOR = '_';
